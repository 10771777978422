import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Dreams from './pages/Dreams';
import ListPodcast from './pages/ListPodcast';
import ListReleases from './pages/ListReleases';
import ListDates from './pages/ListDates';
import ListMixes from './pages/ListMixes'; // ListMixes is the one we are working on
import About from './pages/About';
import NotFound from './components/NotFound'; // Import the 404 component
import Layout from './components/Layout';
import News from './pages/News'


function App() {
  return (
    <Router>
      <Routes>
        {/* Landing page is now the root path */}
        <Route path="/" element={<LandingPage />} />

        {/* Other pages with header and footer */}
        <Route
          path="/dreams"
          element={
            <Layout>
              <Dreams />
            </Layout>
          }
        />
        <Route
          path="/tree"
          element={
            <Layout>
              <News />
            </Layout>
          }
        />
        <Route
          path="/dreaminglive"
          element={
            <Layout>
              <ListPodcast />
            </Layout>
          }
        />
        <Route
          path="/releases"
          element={
            <Layout>
              <ListReleases />
            </Layout>
          }
        />
        <Route
          path="/dates"
          element={
            <Layout>
              <ListDates />
            </Layout>
          }
        />
        {/* Add dynamic route for ListMixes with selectedOption */}
        <Route
          path="/mixes/:selectedOption"
          element={
            <Layout>
              <ListMixes />
            </Layout>
          }
        />
        {/* Keep a route for /mixes without a selectedOption as well */}
        <Route
          path="/mixes"
          element={
            <Layout>
              <ListMixes />
            </Layout>
          }
        />

        {/* About page with header/footer */}
        <Route
          path="/about"
          element={
            <Layout>
              <About />
            </Layout>
          }
        />

        {/* Fallback for unknown routes, rendering the 404 component */}
        <Route path="*" element={<NotFound />} /> {/* 404 Page */}
      </Routes>
    </Router>
  );
}

export default App;
