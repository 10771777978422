const sortBy = [
    {
        description: "Pinned Mixes",
        image: require('../graphics/dj/pinned.png'),
        type: "pinned"
    },
    {
        description: "By Mood",
        image: require('../graphics/dj/mood.png'),
        type: "mood"
    },
    {
        description: "Radio Shows",
        image: require('../graphics/dj/radio.png'),
        type: "radio"
    },
    {
        description: "All Mixes",
        image: require('../graphics/dj/all.png'),
        type: "all"
    }
];

export default sortBy;

