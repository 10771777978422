import React from 'react';
import { releases } from '../assets/data/releases';
import Thea from '../assets/graphics/theas/thea-music.png';
import './ListReleases.css'; // Import the CSS for styling
import CloudTop from '../assets/graphics/cloud-footer-old.png';
import CloudsBottom from '../assets/graphics/cloud-down-old.png';
import grass from "../assets/graphics/grass.png";


const ListReleases = () => {


    return (
        <>
            <div className='container-rl'>
                <div className="thea">
                    <img src={Thea} alt="podcast" className="thea-image-rl" />
                </div>

                <div className="results-rl">
                    <div className="main-container">
                        <div className="inner-container">

                            {/* Label Section */}
                            <div className="release-section">
                                <h1 className='title-rl'>Label</h1>
                                <div className='listResult'>
                                    {releases.filter(release => release.category === 'label').map(release => (
                                        <a href={release.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="allReleasesLink"
                                            key={release.name}
                                            style={{ cursor: 'pointer' }} /* Force cursor pointer in inline styles */
                                        >
                                            <div className="release-item">
                                                <p className="release-name">{release.name}</p>
                                                <p className="release-details">{release.label}, {release.year}</p>
                                            </div>
                                        </a>
                                    ))}
                                </div>
                            </div>

                            {/* Music Section */}
                            <div className="release-section">
                                <h1 className='title-rl'>Music</h1>
                                <div className='listResult'>
                                    {releases.filter(release => release.category === 'music').map(release => (
                                        <a href={release.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="allReleasesLink"
                                            key={release.name}
                                            style={{ cursor: 'pointer' }} /* Force cursor pointer in inline styles */
                                        >
                                            <div className="release-item">
                                                <p className="release-name">{release.name}</p>
                                                <p className="release-details">{release.label}, {release.year}</p>
                                            </div>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='space-footer'>
                        <img src={grass} alt="cloudTop" className="grass" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListReleases;
