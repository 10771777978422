const sortMood = [
    {
        description: "Dance (All)",
        image: require('../graphics/moods/dance.png'),
        type: "dance",
    },
    {
        description: "Listening (All)",
        image: require('../graphics/moods/listening.png'),
        type: "listening",
    },
    {
        description: "Existential",
        image: require('../graphics/moods/existential.png'),
        type: "existential",
    },
    {
        description: "Transcend",
        image: require('../graphics/moods/trance.png'),
        type: "transcendent",
    },
    {
        description: "Techno but not Techno",
        image: require('../graphics/moods/industrial.png'),
        type: "industrial",

    },
    {
        description: "Happy & Cosmic",
        image: require('../graphics/moods/happy.png'),
        type: "cosmic",
    }
];

export default sortMood;
