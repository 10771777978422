import React, { useEffect, useState } from 'react';
import passportImage from '../assets/graphics/about/passport.png';
import phoneImage from '../assets/graphics/about/phone.png';
import codeImage from '../assets/graphics/about/code.png';
import cloudTop from '../assets/graphics/cloud-footer.png';
import pressImage from '../assets/graphics/about/news.png';
import pressData from '../assets/data/press';
import { Helmet } from 'react-helmet';


import './About.css';  // Import your styles

// Embedded contact object
const contact = {
    about: "Jordanian.DJ, producer, curator, writer and web developer.  Also, the founder of Dreaming Live — a podcast dedicated to uncompromised creative expression  hosting guests like David Lynch, Martin Rev and Lydia Lunch. ",
    contact: [
        { text: "mayssn@gmail.com", link: null }, // Email to copy
        { text: "Instagram Mayss", link: "https://www.instagram.com/mayss_88" },
        { text: "Soundcloud Dreaming Live", link: "https://soundcloud.com/dreaming-live" },
        { text: "Soundcloud Mayss", link: "https://soundcloud.com/mmaayyss" },
        { text: "Bandcamp", link: "https://dreaminglive.bandcamp.com/" }
    ]
};

const About = () => {
    const [copied, setCopied] = useState(false); // State to manage "Copied" message

    useEffect(() => {
        console.log("Contact object:", contact);  // Check if the contact object is available
    }, []);

    // Use optional chaining to handle undefined contact.contact safely
    const contactList = contact?.contact || [];

    // Function to copy the email to the clipboard
    const handleCopyEmail = (email) => {
        navigator.clipboard.writeText(email).then(() => {
            setCopied(true); // Show the "Copied" message
            setTimeout(() => setCopied(false), 2000); // Hide message after 2 seconds
        });
    };

    return (
        <>
            <div className="about-page">
                <div className="aboutMe">
                    <div className="about-content">
                        <div className="passport-section">
                            <img src={passportImage} alt="Passport" className="passport-image" />
                        </div>
                        <div className="about-div">
                            <div className="title">ABOUT ME</div>
                            <div className="about-text">{contact.about}</div>
                        </div>
                    </div>
                    <div className="press">
                        <div className="phone-section">
                            <img src={pressImage} alt="Press" className="press-image" />
                        </div>
                        <div className="press-text">
                            <strong>Press:</strong> {/* Bold the word Press */}
                            {pressData.map((press, index) => (
                                <span key={index}>
                                    <a href={press.link} target="_blank" rel="noopener noreferrer">{press.press}</a>
                                    {index < pressData.length - 1 && ", "}
                                </span>
                            ))}.
                        </div>
                    </div>


                    <div className="contact-section">
                        <div className="phone-section">
                            <img src={phoneImage} alt="Phone" className="phone-image" />
                        </div>
                        <div className="contact-info">
                            {contactList.length > 0 ? (
                                contactList.map((item, index) => (
                                    <div key={index} className="contact-item">
                                        {/* Check if it's the email, and if so, allow it to be clicked for copying */}
                                        {item.link ? (
                                            <a href={item.link} target="_blank" rel="noopener noreferrer">{item.text}</a>
                                        ) : (
                                            <span
                                                onClick={() => handleCopyEmail(item.text)} // Copy email on click
                                                style={{ cursor: 'pointer', color: copied ? 'green' : 'black' }} // Change color on copy
                                            >
                                                {item.text}
                                            </span>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div>No contact information available</div>
                            )}
                        </div>
                        {copied && <div className="copied-message">Copied!</div>} {/* Show "Copied" message */}
                    </div>


                    <div className="website-section">
                        <div className="phone-section">
                            <img src={codeImage} alt="Code" className="code-image" />
                        </div>
                        <div className="website-text">
                            WEBSITE BY ME
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;
