import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Import useNavigate and useParams
import sortBy from '../assets/data/sortBy'; // Data for sorting
import sortMood from '../assets/data/sortMood'; // Data for moods
import Thea from '../assets/graphics/theas/thea-dj.png'; // Thea image
import CloudTop from "../assets/graphics/cloud-footer-old.png"; // Cloud image for footer
import './ListMixes.css'; // CSS file for styling
import CloudsBottom from "../assets/graphics/cloud-down-old.png"; // Correct import
import { mixes } from "../assets/data/mixes"; // Data for mixes
import moodImageDefault from '../assets/graphics/dj/mood.png'; // Import default mood image
import MixesResults from '../components/Results/MixesResults'; // Import the MixesResults component
import { Helmet } from 'react-helmet';

const circle = require("../assets/graphics/dj/circle.png");

const ListMixes = () => {
    const { selectedOption: urlSelectedOption } = useParams(); // Get selectedOption from the URL
    const [sortByText, setSortByText] = useState('Sort Mixes By:'); // Title text
    const [isFixed, setIsFixed] = useState(false); // To check if text is fixed on click
    const [showResults, setShowResults] = useState(false); // Don't show results initially
    const [showMoodBox, setShowMoodBox] = useState(false); // To show mood selection box
    const [selectedOption, setSelectedOption] = useState(urlSelectedOption || null); // Keep the initial option from URL or null
    const [selectedMoodImage, setSelectedMoodImage] = useState(moodImageDefault); // Initially set to the default mood image
    const [selectedButton, setSelectedButton] = useState(null); // Track the selected button for sorting options
    const [selectedMoodButton, setSelectedMoodButton] = useState(null); // Track the selected button specifically for mood
    const [resetAction, setResetAction] = useState(false); // Track reset actions for MixesResults
    const moodBoxRef = useRef(null); // Ref for detecting clicks outside the box
    const navigate = useNavigate(); // For navigation

    // Update the selectedOption and selectedButton based on URL when the component loads
    useEffect(() => {
        if (urlSelectedOption) {
            setSelectedOption(urlSelectedOption);
            setShowResults(true); // Ensure results are shown when navigating directly via URL

            // Find the index for the corresponding selectedOption in sortBy or sortMood
            const sortByIndex = sortBy.findIndex(item => item.type === urlSelectedOption);
            const sortMoodIndex = sortMood.findIndex(item => item.type === urlSelectedOption);

            // If the selected option is in sortBy, set the button
            if (sortByIndex !== -1) {
                setSelectedButton(sortByIndex);
            } else if (sortMoodIndex !== -1) {
                // If it's a mood, set the mood button and selected image
                setSelectedMoodButton(true);
                setSelectedMoodImage(sortMood[sortMoodIndex].image);
            } else {
                // If neither sortBy nor sortMood contains the selectedOption, redirect to /mixes
                navigate('/mixes');
            }
        }
    }, [urlSelectedOption, navigate]);

    // Handle hover for sortBy buttons (title changes on hover)
    const handleSortByEnter = (description) => {
        setSortByText(description); // Change title on hover
    };

    const handleSortByLeave = () => {
        setSortByText('Sort Mixes By:'); // Reset title on mouse leave
    };

    // Handle click for sortBy buttons (removing the effect of changing the title on click)
    const handleSortByClick = (description, type, index) => {
        setResetAction(prev => !prev); // Reset open mixes on every new sort click
        setSelectedButton(index); // Track the clicked button by index
        setSelectedMoodButton(null); // Reset the mood button when non-mood options are clicked

        if (type === 'mood') {
            setShowMoodBox(!showMoodBox); // Toggle the mood box without changing results
        } else {
            setSelectedOption(type); // Set the selected option for non-mood options
            setShowMoodBox(false); // Hide the mood box for other options
            setShowResults(true); // Show the results div for other sort types
            setSelectedMoodImage(moodImageDefault); // Reset the mood image back to the default
            navigate(`/mixes/${type}`); // Update the URL to reflect the selected option
        }
    };

    // Handle mood selection
    const handleMoodClick = (mood) => {
        setResetAction(prev => !prev); // Reset open mixes when a mood is selected
        setSelectedOption(mood.type); // Set the selected mood option
        setSelectedMoodImage(mood.image); // Change the image to the selected mood's image
        setSelectedButton(null); // Reset the selectedButton to remove the circle from the non-mood button
        setSelectedMoodButton(true); // Set mood as the selected button
        setShowResults(true); // Show the results after mood is selected
        setShowMoodBox(false); // Close the mood box
        navigate(`/mixes/${mood.type}`); // Update the URL to reflect the selected mood
    };

    // Close mood box if clicking outside
    const handleClickOutside = (event) => {
        if (moodBoxRef.current && !moodBoxRef.current.contains(event.target)) {
            setShowMoodBox(false); // Close the mood box
        }
    };

    // Add event listener for clicks outside the box
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className='container-mixes'>
                {/* Thea Character Image and Dynamic Title */}
                <div className="thea">
                    <img src={Thea} alt="Thea DJ" className="thea-image-dj" />
                    <h1 className='title-sort'>{sortByText}</h1> {/* Dynamic title */}
                </div>

                {/* SortBy Image Buttons with Circle Overlay */}
                <div className="sort-grid">
                    {sortBy.map((item, index) => (
                        <div className="grid-item-sort" key={index}>
                            <div className="circle-container">
                                {selectedButton === index && (!selectedMoodButton || item.type !== 'mood') && (
                                    <img
                                        src={circle}
                                        alt="Selected"
                                        className="circle-overlay"
                                    />
                                )}
                                {item.type === 'mood' && selectedMoodButton && (
                                    <img
                                        src={circle}
                                        alt="Selected"
                                        className="circle-overlay"
                                    />
                                )}
                                {item.type === 'mood' && selectedMoodImage ? (
                                    <>
                                        <img
                                            src={selectedMoodImage}
                                            alt="Mood"
                                            className="grid-image-mixes"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => setShowMoodBox(!showMoodBox)} // Toggle the mood box without changing results
                                            onMouseEnter={() => handleSortByEnter(item.description)} // Change title on hover
                                            onMouseLeave={handleSortByLeave} // Reset title on mouse leave
                                        />
                                        <div className="grid-description">Mood</div>
                                    </>
                                ) : (
                                    <>
                                        <img
                                            src={item.image}
                                            alt={item.description}
                                            className="grid-image-mixes"
                                            onClick={() => handleSortByClick(item.description, item.type, index)} // Handle click for non-mood buttons
                                            onMouseEnter={() => handleSortByEnter(item.description)} // Change title on hover
                                            onMouseLeave={handleSortByLeave} // Reset title on mouse leave
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <div className="grid-description">{item.description}</div>
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Mood Selection Box (If "Mood" Sort is Clicked) */}
                {showMoodBox && (
                    <div className="mood-box" ref={moodBoxRef}>
                        <h2 className="select-mood-title">Select Mood</h2> {/* Add this line */}
                        {sortMood.map((mood, index) => (
                            <div key={index} className="mood-option" onClick={() => handleMoodClick(mood)}>
                                <img src={mood.image} alt={mood.description} className="mood-image" />
                                <span>{mood.description}</span>
                            </div>
                        ))}
                    </div>
                )}


                {/* Conditionally Render the MixesResults Component */}
                {showResults && selectedOption && (
                    <div className="mixResults">
                        <div className='cloudsTop'>
                            <img src={CloudTop} alt="cloudTop" className="cloud-top-mixes" />
                        </div>

                        <div className="results-mixes">
                            <MixesResults
                                mixes={mixes}
                                selectedOption={selectedOption}
                                resetActiveStates={resetAction}
                                sortByText={sortByText}
                                sortBy={sortBy}
                                sortMood={sortMood}
                            />
                        </div>

                        <div className='cloudsBottom'>
                            <img src={CloudsBottom} alt="cloudBottom" className="cloud-bottom-mixes" />
                        </div>
                    </div>
                )}
                <div className='space-footer-mixes'>
                    <div className='cloudsTop'>
                        <img src={CloudTop} alt="cloudTop" className="cloud-footer-mixes" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListMixes;
