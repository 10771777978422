const dates = [
    { 'date': '29/09/2024', 'event': 'TBA, Brussels' },
    { 'date': '28/09/2024', 'event': 'Estafette, Halles De Schaerbeek, Brussels' },
    { 'date': '27/09/2024', 'event': 'Mutant Modality, Cafe Central, Brussels' },
    { 'date': '27/10/2024', 'event': 'Kiosk Radio, Brussels' },
    { 'date': '22/06/2024', 'event': 'Waking Life Festival, Crato' },
    { 'date': '18/06/2024', 'event': 'Lusophonica, Cascais' },
    { 'date': '07/06/2024', 'event': 'Cross Club, Prague' },
    { 'date': '06/06/2024', 'event': 'Struma+Iodine, Café Central, Vienna' },
    { 'date': '03/04/2024', 'event': 'Metaphore Collectif & Lyl Radio, Marseilles' },
    { 'date': '21/03/2024', 'event': 'Arkaoda, Berlin' },
    { 'date': '13/03/2024', 'event': 'Velvet Collective, Dusseldorf' },
    { 'date': '08/03/2024', 'event': 'Salon Des Amateurs, Dusseldorf' },
];

export default dates;
