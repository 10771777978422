import React from 'react';
import datesData from '../assets/data/dates'; // Import the dates data
import backgroundthea from "../assets/graphics/dates/background-thea.png";
import background from "../assets/graphics/dates/background.png";
import "./ListDates.css"; // Corrected path to CSS


const ListDates = () => {
    // Sort the dates array in reverse chronological order
    const sortedDates = datesData.sort((a, b) => new Date(b.date) - new Date(a.date));

    return (
        <div className="list-dates-background">
            <div className="dates-container">
                <div className="title-div">
                    <h1 className="title-text">Dates:</h1>
                </div>
                <div className="dates-list">
                    {sortedDates.map((item, index) => (
                        <div className="date-item" key={index}>
                            <div className="date">{item.date}</div>
                            <div className="event">{item.event}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ListDates;
