import React, { useEffect, useState } from 'react';
import { links } from '../assets/data/newsData';
import './News.css'; // Import the CSS file for styling
import photo from "../assets/graphics/about/photo.jpg"; // Correct the photo import

const News = () => {
    const [linksWithMeta, setLinksWithMeta] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // State to track loading status

    useEffect(() => {
        const fetchLinkMetadata = async () => {
            try {
                const fetchedLinks = await Promise.all(
                    links.map(async (link) => {
                        try {
                            const response = await fetch(`https://api.microlink.io?url=${link.url}`);
                            const data = await response.json();
                            return {
                                ...link,
                                thumbnail: data.data.image?.url || '', // Get the image from Microlink response
                            };
                        } catch (error) {
                            console.error('Error fetching metadata:', error);
                            return link;
                        }
                    })
                );
                setLinksWithMeta(fetchedLinks);
            } catch (error) {
                console.error('Error fetching links:', error);
            } finally {
                setIsLoading(false); // Set loading to false after fetching is complete
            }
        };

        fetchLinkMetadata();
    }, []);

    return (
        <div className="linktree-container">
            <img src={photo} alt="Mayss photo" className='tree-photo' />
            <div className='tree-name'>Mayss ✰</div>
            {isLoading ? ( // Show "Loading..." while fetching data
                <div className="loading">Loading...</div>
            ) : (
                <ul className="links-list">
                    {linksWithMeta.map((link) => (
                        <li key={link.id} className="link-item">
                            <a href={link.url} target="_blank" rel="noopener noreferrer">
                                <div className="thumbnail-container">
                                    {link.thumbnail ? (
                                        <img src={link.thumbnail} alt={link.title} className="thumbnail" />
                                    ) : (
                                        <img src="https://via.placeholder.com/80" alt="No thumbnail available" className="thumbnail" />
                                    )}
                                </div>
                                <div className="link-content">
                                    <h2>{link.title}</h2>
                                </div>
                            </a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default News;
