export const episodes = [
    {
        episode: 1,
        artist: "Vladimir Ivkovic",
        title: "Slippery Floors",
        pinned: null,
        link: "https://soundcloud.com/dreaming-live/vladimir"
    },
    {
        episode: 2,
        artist: "Hans-Joachim & Christine Martha Roedelius",
        title: "On Persistence",
        pinned: null,
        link: "https://soundcloud.com/dreaming-live/roedelius"
    },
    {
        episode: 3,
        artist: "Martin Rev/ Suicide",
        title: "Dreamers are Always the Future",
        pinned: 1,
        link: "https://soundcloud.com/dreaming-live/rev"
    },
    {
        episode: 4,
        artist: "Drew McDowall/ Coil",
        title: "Past Impositions reassemble into our Order",
        pinned: null,
        link: "https://soundcloud.com/dreaming-live/drew"
    },
    {
        episode: 5,
        artist: "David Lynch",
        title: "Between Wake & Sleep",
        pinned: 3,
        link: "https://soundcloud.com/dreaming-live/lynch"
    },
    {
        episode: 6,
        artist: "Alexandrea Archuleta (Aja)",
        title: "On Deconditioning",
        pinned: null,
        link: "https://soundcloud.com/dreaming-live/aja"
    },
    {
        episode: 7,
        artist: "Roxy Farman/ Wetware",
        title: "Your choices are our life's greatest performance",
        pinned: null,
        link: "https://soundcloud.com/dreaming-live/roxy"
    },
    {
        episode: 8,
        artist: "Secret Pyramid",
        title: "On Imagination",
        pinned: null,
        link: "https://soundcloud.com/dreaming-live/secretpyramid"
    },
    {
        episode: 9,
        artist: "Michelle Coltrane",
        title: "Tribute To Alice Coltrane",
        pinned: null,
        link: "https://soundcloud.com/dreaming-live/alice"
    },
    {
        episode: 10,
        artist: "Marshall Allen/ The Sun Ra Arkestra",
        title: "Activate your spirit",
        pinned: null,
        link: "https://soundcloud.com/dreaming-live/sunra"
    },
    {
        episode: 11,
        artist: "Mike Banks/ UR",
        title: "The Underground Resistance",
        pinned: 2,
        link: "https://soundcloud.com/dreaming-live/mikebanks"
    },
    {
        episode: 12,
        artist: "Larry Heard/ Mr. Fingers",
        title: "The Depths of Your Ingenuity",
        pinned: null,
        link: "https://soundcloud.com/dreaming-live/larry"
    },
    {
        episode: 13,
        artist: "Damo Suzuki - CAN",
        title: "Experience is the beginning of truth",
        pinned: null,
        link: "https://soundcloud.com/dreaming-live/damo"
    },
    {
        episode: 14,
        artist: "Vashti Bunyan",
        title: "Keep Your Dreams",
        pinned: null,
        link: "https://soundcloud.com/dreaming-live/vashti"
    },
    {
        episode: 15,
        artist: "DJ Marcelle",
        title: "Are Dancefloore Free?",
        pinned: null,
        link: "https://soundcloud.com/dreaming-live/dj-marcelle"
    },
    {
        episode: 16,
        artist: "Lydia Lunch",
        title: "Wars, and Inner Wars",
        pinned: null,
        link: "https://soundcloud.com/dreaming-live/lydialunch"
    },
    {
        episode: 17,
        artist: "Marie Davidson",
        title: "Person, Ego & Shadow",
        pinned: null,
        link: "https://soundcloud.com/dreaming-live/marie"
    },
    {
        episode: 18,
        artist: "Surgeon",
        title: "From Void into Infinity",
        pinned: null,
        link: "https://soundcloud.com/user-202286394-991268468/tresor-x-dreaming-live-surgeon-060423"
    },
    {
        episode: 19,
        artist: "Karl O'Connor/ Regis",
        title: "Inspiration Shapes Us",
        pinned: null,
        link: "https://soundcloud.com/dreaming-live/regis"
    }
];