import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Thea from '../assets/graphics/theas/thea-wand.png';
import mixesImage from '../assets/graphics/dreams/theamixes.png';
import podcastImage from '../assets/graphics/dreams/theapodcast.png';
import releasesImage from '../assets/graphics/dreams/theareleases.png';
import datesImage from '../assets/graphics/dreams/theadates.png';
import './Dreams.css'; // Import the CSS for styling
import CloudTops from "../assets/graphics/cloud-footer-old.png";
import { Helmet } from 'react-helmet';


const Hobbies = () => {
    const [headingText, setHeadingText] = useState('Welcome to my Dreams');
    const navigate = useNavigate(); // For navigation

    // Array of image objects with descriptions and paths
    const imageItems = [
        { src: podcastImage, description: 'My Podcast: Dreaming Live', path: '/dreaminglive' },
        { src: mixesImage, description: 'Explore my DJ mixes!', path: '/mixes' },
        { src: releasesImage, description: 'Label & Releases', path: '/releases' },
        // { src: datesImage, description: 'Dates', path: '/dates' }
    ];

    // Handle image hover
    const handleMouseEnter = (description) => {
        setHeadingText(description); // Change h1 text on hover
    };

    // Handle mouse leave (reset h1 text)
    const handleMouseLeave = () => {
        setHeadingText('My Dreams: (select one)'); // Reset to original text
    };

    // Handle click to navigate to different pages
    const handleClick = (path) => {
        navigate(path); // Navigate to the respective page
    };

    return (
        <>
            <div className='container'>
                <div className="thea">
                    <img src={Thea} alt="Hobbies" className="thea-image-dreams" />
                    <h1 className='dreams-title'>Explore My Dreams</h1>
                </div>
                <div className="hobbies-grid">
                    {
                        imageItems.map((item, index) => (
                            <div className="grid-item" key={index}>
                                <img
                                    src={item.src}
                                    alt={item.description}
                                    className="grid-image"
                                    onMouseEnter={() => handleMouseEnter(item.description)} // Hover to change h1
                                    onMouseLeave={handleMouseLeave} // Reset h1 text on mouse leave
                                    onClick={() => handleClick(item.path)} // Click to navigate
                                />
                                <h1 className="grid-description">{item.description}</h1> {/* Add description */}
                            </div>
                        ))
                    }
                </div>

                {/* Clouds div moved outside the map function */}
                <div className='clouds-dreams'>
                    <img src={CloudTops} alt="cloudTop" className="cloud-top-dreams" />
                </div>
            </div >
        </>
    );
};

export default Hobbies;
